import React from "react";
import Invite from "./views/Invite";
import Success from "./lottie/Create";
import NotFound from "./lottie/NotFound";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/yaadein/:id" element={<Invite />} />
        <Route path="/Success" element={<Success />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Router>
  )
};

export default App;