import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import card from "../assets/card.svg";
import close from "../assets/close.svg";
import axios from "axios";
import * as htmlToImage from "html-to-image";
import { submitYaadeinVideo } from "../helper";
import { useNavigate } from "react-router-dom";

const TextCardModal = ({ show, onHide, userEmail, userName, yaadeinId, loading, setLoading }) => {

  const [message, setMessage] = useState("");
  // const [loading, setLoading] = useState(false);
  const domEl = useRef(null);
  const navigate = useNavigate();

  const downloadAndSaveImage = async () => {
    try {
      setLoading(true);
      // convert card to image
      const element = domEl.current;
      const dataUrlBlob = await htmlToImage.toBlob(element);

      // create a file
      const blob = new Blob([dataUrlBlob], { type: "image/png" });
      blob.lastModifiedDate = new Date();
      blob.name = `text_card_${Date.now()}.png`;

      submitYaadeinVideo(blob, userEmail, yaadeinId, "card", userName, navigate, setLoading);
      onHide();
      setMessage("");
    } catch (error) {
      console.error("textCardError", error);
      setLoading(false);
      onHide();
      setMessage("");
    }
  };

  return (
    <Modal
      // {...props}
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ borderRadius: "15px" }}
    >
      <div className="add_modal">
        <div className="modal_header">
          <div className="icon">
            <img src={card} alt="" />
          </div>
          <div className="title">Text Card</div>
          <button className="close" onClick={onHide}>
            <img src={close} alt="" />
          </button>
        </div>
        <div className="type_your_message" id="domEl" style={{ background: "#fff", color: "#000" }} ref={domEl}>
          <textarea
            cols="30"
            placeholder="Type your message here"
            value={message}
            disabled={loading}
            onChange={e => setMessage(e.target.value)}
          ></textarea>
        </div>
        <div className="modal_footer text-center">
          {
            loading ?
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div> :
              <div className="action">
                <button className="submit" onClick={e => downloadAndSaveImage()}>Save</button>
              </div>
          }
        </div>
      </div>
    </Modal>
  );
};

export default TextCardModal;
