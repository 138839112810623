import React, {
  Fragment,
  useCallback,
  useRef,
  useState,
  useEffect,
} from "react";
import Webcam from "react-webcam";
import { useNavigate } from "react-router-dom";
import Loading from "../lottie/Loading";
import { submitYaadeinVideo, currentBrowserName } from "../helper";
import microphone from "../assets/microphone.svg";
import refresh from "../assets/refresh.svg";
import { isMobile } from 'react-device-detect';

const VideoRecord = ({
  setIsVideoSelected,
  userEmail,
  yaadeinId,
  userName,
}) => {
  const navigate = useNavigate();
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  let mediaRecorder;
  const [loading, setLoading] = useState(false);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [showingUrl, setShowingUrl] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [timer, setTimer] = useState(0);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );
  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    setRecordedChunks([]);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      // mimeType: `${!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari') ? 'video/mp4' : 'video/webm'}`,
      mimeType: currentBrowserName() === "Safari" ? 'video/mp4' : 'video/webm'
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
    setIsRecording(true);
    // navigator.mediaDevices.getUserMedia({ video: true })
    //   .then(stream => {
    //     mediaRecorder = new MediaRecorder(stream);
    //     mediaRecorder.ondataavailable = event => {
    //       recordedChunks.push(event.data);
    //     };
    //     mediaRecorder.onstop = () => {
    //       setRecordedChunks(recordedChunks)
    //     };
    //     mediaRecorder.start();
    //     setIsRecording(true);
    //   })
    //   .catch(err => console.error('Error accessing media devices:', err));
  }, [webcamRef, setCapturing, handleDataAvailable, mediaRecorderRef]);

  const handleStopCaptureClick = useCallback((e) => {
    if (e) e.preventDefault();
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setCapturing(false);
      setIsRecording(false); // Make sure to set isRecording to false to stop the timer
      setTimer(0); // Reset timer
    }
  }, [setCapturing, mediaRecorderRef]);

  useEffect(() => {
    if (recordedChunks && recordedChunks.length > 0) {
      const blob = new Blob(recordedChunks, {
        // type: "video/webm",
        type: currentBrowserName() === "Safari" ? 'video/mp4' : 'video/webm',
      });
      const url = window.URL.createObjectURL(blob);
      setShowingUrl(url);
    }
  }, [recordedChunks]);

  useEffect(() => {
    let interval = null;

    if (isRecording) {
      interval = setInterval(() => {
        setTimer((prevTime) => prevTime + 1);
      }, 1000);
    }

    // Check if 45 seconds have passed
    if (timer >= 45) {
      handleStopCaptureClick(); // Stop recording
    }

    return () => clearInterval(interval);
  }, [isRecording, timer, handleStopCaptureClick]);

  const handleSubmit = () => {
    setLoading(true);
    const file = new File(recordedChunks, `record${new Date().valueOf()}.mp4`, {
      type: currentBrowserName() === "Safari" ? 'video/mp4' : 'video/webm',
    });
    submitYaadeinVideo(
      file,
      userEmail ? userEmail : "",
      yaadeinId,
      "video",
      userName,
      navigate,
      setLoading
    );
  };

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
  };

  const audioConstraints = {
    suppressLocalAudioPlayback: true,
    noiseSuppression: true,
    echoCancellation: true,
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="row gap-2">
            <div
              className="video_screen"
              style={{
                display: `${recordedChunks.length > 0 ? "none" : "block"}`,
              }}
            >
              <Webcam style={isMobile ? ({ aspectRatio: "9/16" }) : ({ aspectRatio: "16/9" })}
                className="webcam_preview"
                audio={true}
                muted={true}
                mirrored={true}
                ref={webcamRef}
                videoConstraints={videoConstraints}
                audioConstraints={audioConstraints}
              />
            </div>
            {recordedChunks.length > 0 && (
              <div className="video_screen">
                <video style={isMobile ? ({ aspectRatio: "9/16" }) : ({ aspectRatio: "16/9" })}
                  className="webcam_preview"
                  src={showingUrl}
                  id="video-replay"
                  controls
                  disablePictureInPicture
                  controlsList="nodownload noplaybackrate"
                ></video>
              </div>
            )}
          </div>
          {
            capturing &&
            <>
              <div className="timer_display">
                <div>
                  <p>
                    {Math.floor(timer / 60)}:{("0" + (timer % 60)).slice(-2)}
                  </p>
                </div>
              </div>
            </>
          }
          <div className="assets_upload_btns">
            {capturing ? (
              <>
                <button
                  className="gradient_btn"
                  onClick={handleStopCaptureClick}
                >
                  Stop Capturing
                </button>
              </>
            ) : (
              <>
                <button
                  className="gradient_btn"
                  onClick={handleStartCaptureClick}
                >
                  <img src={microphone} />
                  &nbsp;
                  {recordedChunks.length > 0
                    ? "Record Again"
                    : "Start Capturing"}
                </button>
                {recordedChunks.length > 0 && (
                  <Fragment>
                    <button className="dark_btn" onClick={handleSubmit}>
                      Submit
                    </button>
                  </Fragment>
                )}
                <button
                  className="trnsprnt_btn"
                  onClick={(e) => setIsVideoSelected(false)}
                >
                  <img src={refresh} />
                  &nbsp; Go Back
                </button>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default VideoRecord;
