import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./submit.css";
import Loading from "../lottie/Loading";
import { submitYaadeinVideo } from "../helper";
import VideoRecord from "../component/VideoRecord";
import AudioRecord from "../component/AudioRecord";
import media_upload from "../assets/media_upload.svg";
import upload from "../assets/upload.svg";
import camera from "../assets/camera.svg";
import mic from "../assets/mic.svg";
import card from "../assets/card.svg";
import close from "../assets/close.svg";
import TextCardModal from "../component/TextCardModal";
import refresh from "../assets/refresh.svg";
import drophere from "../assets/drophere.svg";

const Submit = ({
  setLoading,
  loading,
  setSubmitPage,
  userEmail,
  yaadeinId,
  userName,
  yaadeinDetails,
}) => {
  const navigate = useNavigate();

  const [uploadedFile, setUploadedFile] = React.useState(null);
  const [modalShow, setModalShow] = React.useState(false);
  const [isVideoSelected, setIsVideoSelected] = useState(false);
  const [isAudioSelected, setIsAudioSelected] = useState(false);
  const [dragOver, setDragOver] = useState(false);
  const inputUploadFileRef = useRef();
  const acceptedVideoTypes = [
    "video/mp4",
    "video/webm",
    "video/ogg",
    "video/avi",
    "video/msvideo",
    "video/x-msvideo",
    "video/mpeg",
    "video/quicktime",
    "video/x-ms-wmv",
    "video/x-flv",
    "video/x-matroska",
    "video/x-m4v",
    "video/3gpp",
    "video/3gpp2",
    "video/x-ms-asf",
    "video/x-mpegURL",
    "video/H264",
  ];

  useEffect(() => {
    setUploadedFile(null);
  }, []);

  const onClickFileUpload = () => {
    if (inputUploadFileRef.current) {
      inputUploadFileRef.current.value = "";
    }
    inputUploadFileRef.current.click();
  };

  const onFileChangeCapture = () => {
    setLoading(true);

    const MAX_FILE_SIZE = 100 * 1024 * 1024;

    if (uploadedFile.size > MAX_FILE_SIZE) {
      alert("File size exceeds the 100 MB limit. Please select a smaller file.");
      setLoading(false);
      return;
    }

    submitYaadeinVideo(
      uploadedFile,
      userEmail ? userEmail : "",
      yaadeinId,
      uploadedFile?.type?.includes('image') ? "image" : "video",
      userName,
      navigate,
      setLoading
    );
  };

  // drag and drop functionality
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);
    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (acceptedVideoTypes.includes(file.type)) {
        setUploadedFile(file);
        // Optional: Reset the input value if necessary
        if (inputUploadFileRef.current) {
          inputUploadFileRef.current.value = "";
        }
      } else {
        alert("Please select video only");
        return;
      }
    }
  };

  console.log('uploadedFile', uploadedFile)

  return (
    <div>
      {isVideoSelected && (
        <div className="media_submit_wrapper">
          <div className="InviteContent css-1h05724">
            <div className="InviteCard css-8tmyua">
              <div className="css-1t2arcr w-100">
                <div className="media_upload">
                  <img src={media_upload} />
                </div>
                <h3 className="chakra-heading css-wp9238">
                  Submit video message for {`${yaadeinDetails?.fullName ? `${yaadeinDetails.fullName}'s` : ''}`}{" "}
                  {yaadeinDetails?.celebrationForName || " "}
                </h3>
                <div className="css-7x17c8">
                  <div className="uppy-Container">
                    <div className="uppy-Root" dir="ltr">
                      <div
                        className="uppy-Dashboard uppy-Dashboard--animateOpenClose uppy-size--md uppy-size--height-md uppy-Dashboard--isInnerWrapVisible"
                        data-uppy-theme="light"
                        data-uppy-num-acquirers="7"
                        data-uppy-drag-drop-supported="true"
                        aria-hidden="false"
                        aria-disabled="false"
                        aria-label="Uppy Dashboard"
                      >
                        <div
                          aria-hidden="true"
                          className="uppy-Dashboard-overlay"
                          tabIndex="-1"
                        ></div>
                        <div
                          className="uppy-Dashboard-inner video_screen_preview"
                          aria-modal="false"
                        // style={{ width: "750px", height: "550px" }}
                        >
                          <div className="uppy-Dashboard-innerWrap light_bg">
                            <div>
                              <p className="info_text text-center">
                                * You can record Video up to 1 minutes in
                                length. Ensure your recordings capture
                                everything you need within this duration.
                              </p>
                              <div className="uppy-Dashboard-AddFiles">
                                <div className="w-100">
                                  <VideoRecord
                                    setIsVideoSelected={setIsVideoSelected}
                                    userEmail={userEmail}
                                    userName={userName}
                                    yaadeinId={yaadeinId}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="upload_footer">
                  <div className="css-q76kqc">
                    <p className="chakra-text css-be7bzb">
                      You can also upload using a Mobile Device 📱
                    </p>
                  </div>
                  <div className="chakra-stack css-14lxv93">
                    <button
                      type="button"
                      className="gradient_btn"
                      onClick={(e) => setSubmitPage(false)}
                    >
                      Back to Invite
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isAudioSelected && (
        <div className="media_submit_wrapper">
          <div className="InviteContent css-1h05724">
            <div className="InviteCard css-8tmyua">
              <div className="css-1t2arcr w-100">
                <div className="media_upload">
                  <img src={media_upload} />
                </div>
                <h3 className="chakra-heading css-wp9238">
                  Submit video message for {`${yaadeinDetails?.fullName ? `${yaadeinDetails.fullName}'s` : ''}`}{" "}
                  {yaadeinDetails?.celebrationForName || " "}
                </h3>
                <div className="css-7x17c8">
                  <div className="uppy-Container">
                    <div className="uppy-Root" dir="ltr">
                      <div
                        className="uppy-Dashboard uppy-Dashboard--animateOpenClose uppy-size--md uppy-size--height-md uppy-Dashboard--isInnerWrapVisible"
                        data-uppy-theme="light"
                        data-uppy-num-acquirers="7"
                        data-uppy-drag-drop-supported="true"
                        aria-hidden="false"
                        aria-disabled="false"
                        aria-label="Uppy Dashboard"
                      >
                        <div
                          aria-hidden="true"
                          className="uppy-Dashboard-overlay"
                          tabIndex="-1"
                        ></div>
                        <div
                          className="uppy-Dashboard-inner"
                          aria-modal="false"
                        // style={{ width: "750px", height: "550px" }}
                        >
                          <div className="uppy-Dashboard-innerWrap light_bg">
                            <div>
                              <p className="info_text text-center">
                                * You can record Audio up to 1 minutes in
                                length. Ensure your recordings capture
                                everything you need within this duration.
                              </p>
                              <div className="uppy-Dashboard-AddFiles">
                                <AudioRecord
                                  setIsAudioSelected={setIsAudioSelected}
                                  userEmail={userEmail}
                                  yaadeinId={yaadeinId}
                                  userName={userName}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="upload_footer">
                  <div className="css-q76kqc">
                    <p className="chakra-text css-be7bzb">
                      You can also upload using a Mobile Device 📱
                    </p>
                  </div>
                  <div className="chakra-stack css-14lxv93">
                    <button
                      type="button"
                      className="gradient_btn"
                      onClick={(e) => setSubmitPage(false)}
                    >
                      Back to Invite
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isAudioSelected && !isVideoSelected && (
        <>
          <div className="media_submit_wrapper">
            <div className="InviteContent css-1h05724">
              <div className="InviteCard css-8tmyua">
                <div className="css-1t2arcr w-100">
                  <div className="media_upload">
                    <img src={media_upload} />
                  </div>
                  <h3 className="chakra-heading css-wp9238">
                    Submit video message for {`${yaadeinDetails?.fullName ? `${yaadeinDetails.fullName}'s` : ''}`}{" "}
                    {yaadeinDetails?.celebrationForName || " "}
                  </h3>
                  <div className="css-7x17c8">
                    <div className="uppy-Container">
                      <div className="uppy-Root" dir="ltr">
                        <div
                          className="uppy-Dashboard uppy-Dashboard--animateOpenClose uppy-size--md uppy-size--height-md uppy-Dashboard--isInnerWrapVisible"
                          data-uppy-theme="light"
                          data-uppy-num-acquirers="7"
                          data-uppy-drag-drop-supported="true"
                          aria-hidden="false"
                          aria-disabled="false"
                          aria-label="Uppy Dashboard"
                        >
                          <div
                            aria-hidden="true"
                            className="uppy-Dashboard-overlay"
                            tabIndex="-1"
                          ></div>
                          <div
                            className="uppy-Dashboard-inner"
                            aria-modal="false"
                          // style={{ width: "750px", height: "550px" }}
                          >
                            <div
                              className={`uppy-Dashboard-innerWrap ${dragOver ? "drag-over" : ""
                                }`}
                              onDragEnter={handleDragEnter}
                              onDragOver={handleDragOver}
                              onDragLeave={handleDragLeave}
                              onDrop={handleDrop}
                            >
                              <div>
                                {!loading && (
                                  <>
                                    {uploadedFile && uploadedFile?.type?.includes('video') ? (
                                      <div className="upload_file_preview">
                                        <video
                                          className="webcam_preview"
                                          src={URL.createObjectURL(
                                            uploadedFile
                                          )}
                                          id="video-replay"
                                          controls
                                          disablePictureInPicture
                                          controlsList="nodownload noplaybackrate"
                                        ></video>
                                        <button
                                          onClick={(e) => {
                                            URL.revokeObjectURL(uploadedFile);
                                            setUploadedFile(null);
                                          }}
                                        >
                                          <img src={close} />
                                        </button>
                                        {/* <p>Daydreamer</p> */}
                                      </div>
                                    ) : uploadedFile && uploadedFile?.type?.includes('image') ? (
                                      <div className="upload_file_preview">
                                        <img src={URL.createObjectURL(
                                          uploadedFile
                                        )} alt=""></img>
                                        <button
                                          onClick={(e) => {
                                            URL.revokeObjectURL(uploadedFile);
                                            setUploadedFile(null);
                                          }}
                                        >
                                          <img src={close} />
                                        </button>
                                        {/* <p>Daydreamer</p> */}
                                      </div>
                                    ) : ''}
                                  </>
                                )}
                                {!dragOver ? (
                                  <div className="uppy-Dashboard-AddFiles">
                                    {isAudioSelected && (
                                      <>
                                        {/* <AudioRecord
                                        setIsAudioSelected={setIsAudioSelected}
                                        userEmail={userEmail}
                                        yaadeinId={yaadeinId}
                                        userName={userName}
                                      /> */}
                                      </>
                                    )}
                                    {loading ? (
                                      <Loading />
                                    ) : (
                                      <>
                                        <div className="uppy-Dashboard-AddFiles-title">
                                          Drag and Drop files here or choose one
                                          of the following options to record or
                                          upload media
                                        </div>
                                        <div
                                          className="uppy-Dashboard-AddFiles-list"
                                          role="tablist"
                                        >
                                          <div
                                            className="uppy-DashboardTab"
                                            role="presentation"
                                            data-uppy-acquirer-id="MyDevice"
                                          >
                                            <button
                                              type="button"
                                              className="uppy-u-reset uppy-c-btn uppy-DashboardTab-btn"
                                              role="tab"
                                              tabIndex="0"
                                              data-uppy-super-focusable="true"
                                              onClick={(e) =>
                                                onClickFileUpload()
                                              }
                                            >
                                              <input
                                                type="file"
                                                className="d-none"
                                                ref={inputUploadFileRef}
                                                // onChangeCapture={
                                                //   onFileChangeCapture
                                                // }
                                                onChange={(e) => {
                                                  const file =
                                                    e.target.files[0];
                                                  if (file) {
                                                    setUploadedFile(file);
                                                    e.target.value = "";
                                                  }
                                                }}
                                                accept="video/mp4,video/x-m4v,video/*,image/*"
                                              />
                                              <div className="uppy-DashboardTab-inner">
                                                <img src={upload} />
                                              </div>
                                              <div className="uppy-DashboardTab-name">
                                                Upload
                                              </div>
                                            </button>
                                          </div>
                                          <div
                                            className="uppy-DashboardTab"
                                            role="presentation"
                                            data-uppy-acquirer-id="Webcam"
                                          >
                                            <button
                                              type="button"
                                              className="uppy-u-reset uppy-c-btn uppy-DashboardTab-btn"
                                              role="tab"
                                              tabIndex="0"
                                              data-cy="Webcam"
                                              aria-controls="uppy-DashboardContent-panel--Webcam"
                                              aria-selected="false"
                                              data-uppy-super-focusable="true"
                                              onClick={(e) =>
                                                setIsVideoSelected(true)
                                              }
                                            >
                                              <div className="uppy-DashboardTab-inner">
                                                <img src={camera} />
                                              </div>
                                              <div className="uppy-DashboardTab-name">
                                                Camera
                                              </div>
                                            </button>
                                          </div>
                                          <div
                                            className="uppy-DashboardTab"
                                            role="presentation"
                                            data-uppy-acquirer-id="Audio"
                                          >
                                            <button
                                              type="button"
                                              className="uppy-u-reset uppy-c-btn uppy-DashboardTab-btn"
                                              role="tab"
                                              tabIndex="0"
                                              data-cy="Audio"
                                              aria-controls="uppy-DashboardContent-panel--Audio"
                                              aria-selected="false"
                                              data-uppy-super-focusable="true"
                                              onClick={(e) =>
                                                setIsAudioSelected(true)
                                              }
                                            >
                                              <div className="uppy-DashboardTab-inner">
                                                <img src={mic} />
                                              </div>
                                              <div className="uppy-DashboardTab-name">
                                                Audio
                                              </div>
                                            </button>
                                          </div>
                                          <div
                                            className="uppy-DashboardTab"
                                            role="presentation"
                                            data-uppy-acquirer-id="Audio"
                                          >
                                            <button
                                              type="button"
                                              className="uppy-u-reset uppy-c-btn uppy-DashboardTab-btn"
                                              role="tab"
                                              tabIndex="0"
                                              data-cy="Audio"
                                              aria-controls="uppy-DashboardContent-panel--Audio"
                                              aria-selected="false"
                                              onClick={() => setModalShow(true)}
                                              data-uppy-super-focusable="true"
                                            >
                                              <div className="uppy-DashboardTab-inner">
                                                <img src={card} />
                                              </div>
                                              <div className="uppy-DashboardTab-name">
                                                Text Card
                                              </div>
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                ) : (
                                  <div className="uppy-Dashboard-AddFiles">
                                    <div className="uppy-Dashboard-AddFiles-title">
                                      Drop files here
                                    </div>
                                    <div
                                      className="uppy-Dashboard-AddFiles-list"
                                      role="tablist"
                                    >
                                      <div
                                        className="uppy-DashboardTab"
                                        role="presentation"
                                        data-uppy-acquirer-id="Audio"
                                      >
                                        <img src={drophere} />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            className="assets_upload_btns"
                            style={{ margin: "0 16px 35px" }}
                          >
                            {uploadedFile && (
                              <button
                                className="gradient_btn"
                                disabled={loading}
                                onClick={(e) => onFileChangeCapture()}
                              >
                                Submit
                              </button>
                            )}
                            <button
                              className="trnsprnt_btn"
                              disabled={loading}
                              onClick={(e) => setSubmitPage(false)}
                            >
                              <img src={refresh} />
                              &nbsp; Go Back
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="upload_footer">
                    <div className="css-q76kqc">
                      <p className="chakra-text css-be7bzb">
                        You can also upload using a Mobile Device 📱
                      </p>
                    </div>
                    <div className="chakra-stack css-14lxv93">
                      <button
                        type="button"
                        className="gradient_btn"
                        onClick={(e) => setSubmitPage(false)}
                      >
                        Explore More
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <TextCardModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        userEmail={userEmail || ""}
        userName={userName || ""}
        yaadeinId={yaadeinId}
        loading={loading}
        setLoading={setLoading}
      />
    </div>
  );
};

export default Submit;
