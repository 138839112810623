import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../lottie/Loading";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import { submitYaadeinVideo } from "../helper";
import play from "../assets/play.svg";
import refresh from "../assets/refresh.svg";

const AudioRecord = ({
  setIsAudioSelected,
  userEmail,
  yaadeinId,
  userName,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [recordState, setRecordState] = useState(null);
  const [audio, setAudioData] = useState("");
  const [notRecording, setNotRecording] = useState(true);
  const [timer, setTimer] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    if (recordState === RecordState.START) {
      const id = setInterval(() => {
        setTimer((prevTimer) => {
          // Check if timer has reached 2 minutes (120 seconds)
          if (prevTimer >= 60) {
            clearInterval(id); // Stop the interval
            stop(); // Automatically stop the recording
            return prevTimer; // Return current timer value without incrementing
          }
          return prevTimer + 1; // Increment timer
        });
      }, 1000);
      setIntervalId(id);
    } else if (recordState === RecordState.STOP) {
      if (intervalId) {
        clearInterval(intervalId); // Clear the interval when recording stops
      }
      setTimer(0); // Reset timer
    }
    // Cleanup interval on component unmount
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [recordState]);

  const start = () => {
    setRecordState(RecordState.START);
    setNotRecording(false);
  };

  const stop = () => {
    setRecordState(RecordState.STOP);
    setNotRecording(true);
  };

  const onStop = (audioData) => {
    setAudioData(audioData);
  };

  const handleSubmit = () => {
    setLoading(true);
    const file = new File([audio.blob], `audio${new Date().valueOf()}.mp3`, {
      type: "audio/mp3",
    });
    submitYaadeinVideo(
      file,
      userEmail ? userEmail : "",
      yaadeinId,
      "audio",
      userName,
      navigate,
      setLoading
    );
  };

  // Format timer to display as MM:SS
  const formatTimer = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="w-100">
          <div className="video_screen mb-3" style={{ display: `${audio.url && notRecording ? 'none' : 'block'}` }}>
            <AudioReactRecorder state={recordState} onStop={onStop} />
          </div>
          {
            !notRecording &&
            <div className="timer_display">
              <div>
                <p>{formatTimer()}</p>
              </div>
            </div>
          }
          {/* Display the formatted timer */}
          {audio.url && notRecording && (
            <audio
              className="audio_controls"
              src={audio.url}
              controls
              controlsList="nodownload noplaybackrate"
            />
          )}
          <div className="assets_upload_btns">
            {notRecording && (
              <button className="gradient_btn" onClick={start}>
                <img src={play} alt="Start recording" />
                &nbsp;
                {
                  audio.url ?
                    "Record again" :
                    "Start"
                }
              </button>
            )}
            {!notRecording && (
              <button className="gradient_btn" onClick={stop}>
                Stop
              </button>
            )}
            {audio.url && notRecording && (
              <button className="dark_btn" onClick={(e) => handleSubmit()}>
                Submit
              </button>
            )}
            {notRecording && (
              <button
                className="trnsprnt_btn"
                onClick={(e) => setIsAudioSelected(false)}
              >
                <img src={refresh} alt="Go back" />
                &nbsp; Go Back
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AudioRecord;
