import axios from "axios";

export const getYaadeinDetails = async (id, email) => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/yaadein/submit/details/${id}/v1?email=${email}`;
        const response = await axios.get(url);
        return response.data?.data;
    } catch (error) {
        console.error("error", error.message);
    }
};

export const submitYaadeinVideo = async (file, email, id, type, name, navigate, setLoading) => {
    try {
        if (!file) {
            console.log("Something went wrong with submit video");
            return;
        }
        // upload yaadein media
        const url = `${process.env.REACT_APP_BASE_URL}/s3/upload/yaadein/media/v1`;
        const imgRes = await axios.post(url, {
            sFileName: file.name,
            sContentType: file.type
        });
        const imageUrl = imgRes.data.data.sUrl
        const video = imgRes.data.data.sPath
        const putData = await axios.put(imageUrl, file, { headers: { "Content-Type": file.type } });
        if (putData.status !== 200) {
            console.log("Something went wrong when video upload after s3 bucket status code: ", putData.status);
            return;
        }
        const apiUrl = `${process.env.REACT_APP_BASE_URL}/yaadein/submit/yaadein/video/v1?email=${email}&name=${name}`;
        const uploaded = await axios.post(apiUrl, { id, type: type, url: video })
        if (uploaded.status === 200) {
            navigate("/Success");
            setLoading(false);
        }
    } catch (error) {
        console.error("error", error.message);
    }
};

export const currentBrowserName = () => {
    var nAgt = navigator.userAgent;
    var browserName = navigator.appName;
    var fullVersion = '' + parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion, 10);
    var nameOffset, verOffset, ix;

    // In Opera, the true version is after "OPR" or after "Version"
    if ((verOffset = nAgt.indexOf("OPR")) != -1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 4);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In MS Edge, the true version is after "Edg" in userAgent
    else if ((verOffset = nAgt.indexOf("Edg")) != -1) {
      browserName = "Microsoft Edge";
      fullVersion = nAgt.substring(verOffset + 4);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
      browserName = "Microsoft Internet Explorer";
      fullVersion = nAgt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome" 
    else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
      browserName = "Chrome";
      fullVersion = nAgt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version" 
    else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
      browserName = "Safari";
      fullVersion = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox" 
    else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
      browserName = "Firefox";
      fullVersion = nAgt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent 
    else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
      (verOffset = nAgt.lastIndexOf('/'))) {
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);
      if (browserName.toLowerCase() == browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) != -1)
      fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) != -1)
      fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt('' + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion = '' + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }
    
    return `${browserName}`
  }