import React from "react";
import Lottie from "react-lottie";
import LOADING from "./json/loading.json";

const Loading = () => {

  const lottie1 = {
    loop: true,
    autoplay: true,
    animationData: LOADING
  };

  return (
    <>
      <div style={{ width: "100%", height: "35vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Lottie options={lottie1} style={{ width: "200px", height: "200px" }} />
      </div>
    </>
  )
};

export default Loading;