import React from "react";
import Lottie from "react-lottie";
import CREATE from "./json/create.json";
import { useNavigate } from "react-router-dom";
import decor from "../assets/decor.svg";
import success_logo from "../assets/yaadein_blank_logo.svg";
import animationData from "../lottie/json/party-celebration.json";
import animationData1 from "../lottie/json/party-celebration1.json";
import animationData2 from "../lottie/json/party-celebration2.json";

const CreatedSuccess = () => {
  const navigate = useNavigate();
  // const lottie1 = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: CREATE,
  // };

  const lottie1 = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const lottie2 = {
    loop: true,
    autoplay: true,
    animationData: animationData1,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const lottie3 = {
    loop: true,
    autoplay: true,
    animationData: animationData2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <div className="success_page_wrapper">
        <div className="lottie1">
          <Lottie options={lottie3} height={160} width="100%" />
        </div>
        <div className="lottie2">
          <Lottie options={lottie3} height={160} width="100%" />
        </div>
        <div className="lottie3">
          <Lottie options={lottie1} height="100%" width="100%" />
        </div>
        <div className="lottie4">
          <Lottie options={lottie2} height="100%" width={300} />
        </div>
        <div className="media_submit_wrapper">
          <div className="InviteContent css-1h05724">
            <div className="success_page_box">
              <img src={decor} />
              <div className="css-1t2arcr w-100">
                <div className="css-7x17c8">
                  <img src={success_logo} style={{ width: "150px", height: "150px" }} />
                  <h4>Success! Your Yaadein video has been beautifully captured and is ready to shine.</h4>
                </div>
                <div className="upload_footer">
                  <div className="chakra-stack css-14lxv93">
                    <button type="button" className="gradient_btn" onClick={e => window.open(process.env.REACT_APP_YAADEIN_URL)}>
                      Explore More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        style={{
          width: "100%",
          height: "70vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Lottie options={lottie1} style={{ width: "200px", height: "200px" }} />
      </div>
      <p
        style={{
          color: "white",
          width: "100%",
          textAlign: "center",
        }}
      >
        Uploaded successfully
      </p>
      <div className="chakra-stack css-14lxv93">
        <button
          type="button"
          className="chakra-button css-sqxod8"
          onClick={(e) => navigate(-1)}
        >
          Back to Invite
        </button>
      </div> */}
    </>
  );
};

export default CreatedSuccess;