import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import animationData from "../lottie/json/party-celebration.json";
import animationData1 from "../lottie/json/party-celebration1.json";
import animationData2 from "../lottie/json/party-celebration2.json";
import Copy from "../assets/copy.svg";
import Occasions from "../assets/occassion.svg";
import decor from "../assets/decor.svg";
import profile from "../assets/profile.jpg";
import { useParams, useSearchParams } from "react-router-dom";
import { getYaadeinDetails } from "../helper";
import Loading from "../lottie/Loading";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";
import Submit from "./Submit";

const s3BaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

export default function Invite() {
  const [submitPage, setSubmitPage] = useState(false);
  const [userEmailPopup, setUserEmailPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [yaadeinId, setYaadeinId] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [yaadeinDetails, setYaadeinDetails] = useState({});
  const [data] = useSearchParams();

  const id = useParams().id;

  useEffect(() => {
    setYaadeinId(id || "");
    setUserEmail(data.get("email") ? data.get("email") : "");
    setUserName(data.get("name") ? data.get("name") : "");
    if (id) {
      if (Object.keys(yaadeinDetails).length < 1) {
        getDetails();
      }
    }
  }, []);

  // useEffect(() => {
  //   if (yaadeinId) {
  //     if (Object.keys(yaadeinDetails).length < 1) {
  //       getDetails();
  //     }
  //   }
  // }, [yaadeinId]);

  const getDetails = async () => {
    const email = data.get("email") ? data.get("email") : "";
    setPageLoading(true);
    const yaadeindata = await getYaadeinDetails(id, email);
    setYaadeinDetails(yaadeindata);
    setTimeout(() => {
      setPageLoading(false);
    }, 1000);
  };

  const lottie1 = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const lottie2 = {
    loop: true,
    autoplay: true,
    animationData: animationData1,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const lottie3 = {
    loop: true,
    autoplay: true,
    animationData: animationData2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      {pageLoading ? (
        <Loading />
      ) : (
        <>
          {yaadeinDetails && Object.keys(yaadeinDetails).length > 0 ? (
            <>
              {submitPage ? (
                <Submit
                  loading={loading}
                  setLoading={setLoading}
                  yaadeinDetails={yaadeinDetails}
                  setSubmitPage={setSubmitPage}
                  userName={userName}
                  userEmail={userEmail}
                  yaadeinId={yaadeinId}
                />
              ) : (
                <section className="preview_video">
                  <img src={`${s3BaseUrl}${yaadeinDetails.yaadeinInvite.themePhoto}`} />
                  <div className="lottie1">
                    <Lottie options={lottie3} height={160} width="100%" />
                  </div>
                  <div className="lottie2">
                    <Lottie options={lottie3} height={160} width="100%" />
                  </div>
                  <div className="lottie3">
                    <Lottie options={lottie1} height="100%" width="100%" />
                  </div>
                  <div className="lottie4">
                    <Lottie options={lottie2} height="100%" width={300} />
                  </div>
                  <div className="preview_box">
                    <div className="heading" style={{backgroundImage: `url(${s3BaseUrl}${yaadeinDetails.yaadeinInvite.themePhoto})`}}>
                      {
                        yaadeinDetails?.yaadeinInvite?.occasionPhoto &&
                        <div className="image">
                          <img src={`${s3BaseUrl}${yaadeinDetails.yaadeinInvite.occasionPhoto}`} className="h-100" />
                        </div>
                      }
                      <div className="title">
                        Submit your {yaadeinDetails?.celebrationForName} wishing
                        video for
                      </div>
                      <div className="name">{yaadeinDetails?.fullName}</div>
                      {yaadeinDetails &&
                        yaadeinDetails.yaadeinInvite &&
                        yaadeinDetails.yaadeinInvite.hostVideo && (
                          <div className="video">
                            <video
                              controls
                              disablePictureInPicture
                              controlsList="nodownload noplaybackrate"
                              style={{
                                borderRadius: "15px",
                                border:
                                  "1px solid rgba(164, 164, 192, 0.3019607843)",
                                height: "80%",
                                width: "100%",
                                marginBottom: "0px",
                              }}
                            >
                              <source
                                src={`${s3BaseUrl}${yaadeinDetails.yaadeinInvite.hostVideo}`}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        )}
                    </div>
                    <div className="box_content">
                      <textarea
                        className="paragraph form-control"
                        rows={13}
                        // onChange={(e) =>
                        //   console.log("e.target.value", e.target.value)
                        // }
                        value={yaadeinDetails?.yaadeinInvite?.instruction || ""}
                      />
                      <div className="email_invite_submission_btn mt-3 mb-3 d-flex justify-content-center">
                        {/* <button className="btn btn-secondary me-3">RSVP</button> */}
                        {yaadeinDetails?.isUserDeleted ? (
                            <p style={{ color: "red" }}>
                              Uh-oh! The Yaadein video has been whisked away by its creator
                            </p>
                          ) : yaadeinDetails?.finalize ? (
                            <p style={{ color: "red" }}>
                              All set! The Yaadein video is now complete and closed for edits
                            </p>
                          ) : yaadeinDetails?.yaadeinInvite?.submissionDeadline &&
                          new Date(
                            yaadeinDetails.yaadeinInvite.submissionDeadline
                          ).setHours(23,59,59,999) > Date.now() ? (
                          <button
                            className="btn btn-primary"
                            onClick={(e) => {
                              if (userEmail === "") {
                                setUserEmailPopup(true);
                              } else {
                                setSubmitPage(true);
                              }
                            }}
                          >
                            Submit Now
                          </button>
                        ) : (
                          <p style={{ color: "red" }}>
                            Time’s up! Your contribution didn’t make it in time for Yaadein
                          </p>
                        )}
                      </div>
                      {/* <div className="share_link">
                        <div className="content">
                          <div className="title">Share Your Invite Link</div>
                          <a className="link">
                            {process.env.REACT_APP_SUBMIT_YAADEIN_BASE_URL}yaadein/
                            {yaadeinId}
                          </a>
                        </div>
                        <div className="icon">
                          <CopyToClipboard
                            text={`${process.env.REACT_APP_SUBMIT_YAADEIN_BASE_URL}yaadein/${yaadeinId}`}
                            onCopy={() => alert("Copied")}
                          >
                            <img
                              src={Copy}
                              alt=""
                              style={{ cursor: "pointer" }}
                            />
                          </CopyToClipboard>
                        </div>
                      </div> */}
                      <div className="input_box deadline email_invite_deadline">
                        <div className="input_lable ">
                          <div>
                            <img src={Occasions} alt="" />
                            Submission Deadline
                          </div>
                          <div className="deadline_date">
                            {yaadeinDetails.yaadeinInvite.submissionDeadline ? moment(
                              new Date(
                                yaadeinDetails.yaadeinInvite.submissionDeadline
                              )
                            ).format("MMMM Do YYYY") : 'NA'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </>
          ) : (
            <h2>Something went wrong</h2>
          )}
        </>
      )}
      <Modal
        isOpen={userEmailPopup}
        toggle={() => setUserEmailPopup(!userEmailPopup)}
      >
        <ModalHeader toggle={() => setUserEmailPopup(!userEmailPopup)}>
          Add Your Details
        </ModalHeader>
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (userEmail === "" || userName === "") {
                alert("please add email id.");
              } else {
                setUserEmailPopup(!userEmailPopup);
                setSubmitPage(true);
              }
            }}
          >
            <div className="row">
              <div className="col-12 mb-4">
                <div className="d-flex flex-column add_email_modal">
                  <label className="mb-1" htmlFor="name">
                    Name:
                  </label>
                  <input
                    type="text"
                    placeholder="Name"
                    required
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 mb-5">
                <div className="d-flex flex-column add_email_modal">
                  <label className="mb-1" htmlFor="name">
                    Email:
                  </label>
                  <input
                    type="email"
                    placeholder="Email"
                    required
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                  />
                </div>
              </div>
              <br />
              <br />
              <div className="col-12 d-flex justify-content-center">
                <button type="submit" className="btn_primary mx-2">
                  Submit
                </button>
                <Button
                  className="btn_secondary mx-2"
                  onClick={(e) => {
                    e.preventDefault();
                    setUserEmailPopup(!userEmailPopup);
                    setSubmitPage(true);
                    setUserEmail("");
                    setUserName("");
                  }}
                >
                  Skip
                </Button>{" "}
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
}
